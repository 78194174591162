import { Injectable } from '@angular/core';
import {
  routingPathReport, routingPathRobotEpson, routingPathRobotFanuc, routingPathRobotKassow,
  routingPathRobotKawasaki, routingPathRobotTechman, routingPathRobotUr, routingPathRobotYaskawa,
} from '../constants/routing-path.constant';
import { DeviceShortInterface } from '../interfaces/device.interface';
import { RobotStatusService } from './robot-status.service';

/**
 * Service for operations made on Angular route paths
 */
@Injectable({
  providedIn: 'root',
})
export class UrlsService {

  getUUIDFromUrl(url: string): string | undefined {
    const segments: string[] = url.replace(/\?.*$/, '').split('/');
    const segmentIdx: number = segments.findIndex(v => {
      return v.split('-').length === 5;
    });

    return segments[segmentIdx];
  }

  // @TODO remove isDevEnv after the release
  isProductionUrl(): boolean {
    const isProdEnv: boolean = window.location.host.includes('cobotmonitor.com');
    const isDevEnv: boolean = window.location.host.includes('app.dev.kogena.com');

    return isProdEnv || isDevEnv;
  }

  getRoutePath(device: DeviceShortInterface): string {
    if (RobotStatusService.isUR(device)) {
      return routingPathRobotUr(device.id);
    } else if (RobotStatusService.isYaskawa(device)) {
      return routingPathRobotYaskawa(device.id);
    } else if (RobotStatusService.isEpson(device)) {
      return routingPathRobotEpson(device.id);
    } else if (RobotStatusService.isTechman(device)) {
      return routingPathRobotTechman(device.id);
    } else if (RobotStatusService.isKassow(device)) {
      return routingPathRobotKassow(device.id);
    } else if (RobotStatusService.isKawasaki(device)) {
      return routingPathRobotKawasaki(device.id);
    } else if (RobotStatusService.isKuka(device)) {
      return routingPathReport(device.id);
    } else if (RobotStatusService.isFanuc(device)) {
      return routingPathRobotFanuc(device.id);
    }
    // console.error(`getRoutePath: Undefined robot type of ID ${deviceId} for name ${robotType}`);
    return '';
  }
}
