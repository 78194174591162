export enum WarningLevelUREnum {
  DEBUG = 0,
  INFO = 1,
  WARNING = 2,
  VIOLATION = 3,
  FAULT = 4,
  DEVL_DEBUG = 128,
  DEVL_INFO = 129,
  DEVL_WARNING = 130,
  DEVL_VIOLATION = 131,
  DEVL_FAULT = 132,
}
