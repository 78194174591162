import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DictionaryInterface } from '../../interfaces/dictionary.interface';

@Component({
  selector: 'app-table-basic',
  templateUrl: './table-basic.component.html',
  styleUrls: ['./table-basic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class TableBasicComponent {
  @Input() data: DictionaryInterface<string | number>[] = [];
  @Input() config: { columnSizes: number[] } | undefined;

  readonly Object = Object;
}
