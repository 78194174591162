import { Injectable } from '@angular/core';
import { DictionaryInterface } from '../../../../../../shared/interfaces/dictionary.interface';

@Injectable({
  providedIn: 'root',
})
export class LogsKawasakiCategoriesService {
  /**
   * Categories definitions:
   * https://kogena.atlassian.net/wiki/spaces/~620998572/pages/56786947/Message+category
   *
   * API schema uses category code instead of UUID therefore the mapper.
   */
  getCategories(): number[] {
    return [0, 1, 2, 3, 4];
  }

  getCategoriesDev(): number[] {
    return [128, 129, 130, 131, 132];
  }

  getCorrespondingCategory(
    categoryDevCode: number,
  ): number | null {
    switch (categoryDevCode) {
      case 128:
        return 0;
      case 129:
        return 1;
      case 130:
        return 2;
      case 131:
        return 3;
      case 132:
        return 4;
      default:
        console.error('Unknown category DEV code', categoryDevCode);
        return null;
    }
  }

  getCorrespondingCategoryDev(
    categoryCode: number,
  ): number | null {
    switch (categoryCode) {
      case 0:
        return 128;
      case 1:
        return 129;
      case 2:
        return 130;
      case 3:
        return 131;
      case 4:
        return 132;
      default:
        console.error('Unknown category code', categoryCode);
        return null;
    }
  }

  /**
   * Finds the DEV category based on the value of "main" category, e.g.:
   * For "DEBUG" selected "DEVL_DEBUG" should be selected as well.
   * For "INFO" selected "DEVL_INFO" should be selected and so on...
   */
  isCategoryDevCorrespondent(
    categoryDevCode: number,
    categoriesFormValue: DictionaryInterface<boolean>,
  ): boolean {
    return categoriesFormValue[this.getCorrespondingCategory(categoryDevCode) as number];
  }
}
