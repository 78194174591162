import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LogsKawasakiCategoryInterface } from '../../../../../../modules/logs/modules/logs-kawasaki/interfaces/logs-kawasaki-category.interface';
import { LogsKawasakiInterface } from '../../../../../../modules/logs/modules/logs-kawasaki/interfaces/logs-kawasaki.interface';
import { DateRangeInterface } from '../../../../../interfaces/date-range.interface';
import { DictionaryInterface } from '../../../../../interfaces/dictionary.interface';
import { ApiParamsService } from '../../api-params.service';
import { apiUrlLogsKawasaki, apiUrlLogsKawasakiCategories } from '../../../constants/api-url.constant';
import { ApiResourceLogsKawasakiServiceInterface } from './api-resource-logs-kawasaki.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceLogsKawasakiService implements ApiResourceLogsKawasakiServiceInterface {
  constructor(
    private httpClientService: HttpClient,
    private apiParamsService: ApiParamsService,
  ) {}

  getLogs$(deviceId: string, paramsDict: DictionaryInterface<string | number | boolean>): Observable<LogsKawasakiInterface> {
    let params = new HttpParams().appendAll(paramsDict);
    params = this.apiParamsService.removeEmptyValues(params);

    return this.httpClientService.get<LogsKawasakiInterface>(apiUrlLogsKawasaki(deviceId), {
      params,
    }).pipe(
      catchError(() => {
        return of({
          count: 0,
          next: null,
          previous: null,
          results: [],
        });
      }),
    );
  }

  getLogsCategories$(deviceId: string, dateRange: DateRangeInterface): Observable<LogsKawasakiCategoryInterface[]> {
    let params = new HttpParams();
    params = this.apiParamsService.applyDateRange(params, dateRange);
    return this.httpClientService.get<LogsKawasakiCategoryInterface[]>(apiUrlLogsKawasakiCategories(deviceId), {
      params,
    });
  }
}
