import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DateRangeInterface } from '../../../../../../shared/interfaces/date-range.interface';
import {
  ApiResourceUserConfigService,
} from '../../../../../../shared/modules/api/services/api-resource-user-config/api-resource-user-config.service';
import { UserConfigTypeEnum } from '../../../../../../shared/enums/user-config-type.enum';
import { WarningLevelUREnum } from '../../../../../../shared/enums/warning-level-ur.enum';
import { DictionaryInterface } from '../../../../../../shared/interfaces/dictionary.interface';
import { UserConfigInterface } from '../../../../../../shared/interfaces/user-config.interface';
import { LogsKawasakiCategoryInterface } from '../../interfaces/logs-kawasaki-category.interface';
import { LogsKawasakiCategoriesService } from '../../services/logs-kawasaki-categories/logs-kawasaki-categories.service';
import { ApiResourceLogsKawasakiService } from '../../../../../../shared/modules/api/services/api-resource-logs/api-resource-logs-kawasaki/api-resource-logs-kawasaki.service';

@Injectable({
  providedIn: 'root',
})
export class LogsKawasakiFiltersService {
  private configId: string;
  private configType = UserConfigTypeEnum.LOGS_KAWASAKI_PAGE_CATEGORIES;
  private configLabel = `${UserConfigTypeEnum.LOGS_KAWASAKI_PAGE_CATEGORIES}0`;

  constructor(
    private apiResourceLogsKawasakiService: ApiResourceLogsKawasakiService,
    private apiResourceUserConfigService: ApiResourceUserConfigService,
    private logsKawasakiCategoriesService: LogsKawasakiCategoriesService,
  ) {}

  getFiltersCategories$(deviceId: string, dateRange: DateRangeInterface): Observable<LogsKawasakiCategoryInterface[]> {
    return this.apiResourceLogsKawasakiService.getLogsCategories$(deviceId, dateRange);
  }

  saveConfig$(categoriesFormValue: DictionaryInterface<boolean>): Observable<any> {
    const categoriesSelected: number[] = Object.entries(categoriesFormValue).reduce((acc: number[], curr: [string, boolean]) => {
      if (curr[1]) {
        acc.push(Number(curr[0]));
      }

      return acc;
    }, []);
    const config: UserConfigInterface<number[]> = {
      config_type: this.configType,
      label: this.configLabel,
      config: categoriesSelected,
    };

    return this.apiResourceUserConfigService.updateConfig$(
      this.configId,
      config,
    );
  }

  getConfig$(): Observable<UserConfigInterface<number[]>> {
    // @ts-ignore
    return this.apiResourceUserConfigService.getConfigs$(UserConfigTypeEnum.LOGS_KAWASAKI_PAGE_CATEGORIES).pipe(
      switchMap((configs: Omit<UserConfigInterface<number[]>, 'config'>[]) => {
        const configsExist: boolean = configs.length > 0;

        if (configsExist) {
          this.configId = configs[0].id as string;
          this.configLabel = configs[0].label;

          return this.apiResourceUserConfigService.getConfig$<number[]>(this.configId);
        } else {
          return this.apiResourceUserConfigService.createConfig$<number[]>(this.getDefaultConfig()).pipe(
            tap((userConfig: UserConfigInterface) => {
              this.configId = userConfig.id as string;
              this.configLabel = userConfig.label;
            }),
          );
        }
      }),
    );
  }

  isAnyCategoryChecked(categoriesFormGroupValue: DictionaryInterface<boolean>): boolean {
    return Object.entries(categoriesFormGroupValue).filter(([categoryCode, isCategoryChecked]: [string, boolean]) => {
      const isCategory: boolean = this.logsKawasakiCategoriesService.getCategories().includes(Number(categoryCode));

      return isCategory && isCategoryChecked;
    }).length > 0;
  }

  private getDefaultConfig(): Omit<UserConfigInterface<number[]>, 'id'> {
    return {
      config_type: this.configType,
      label: this.configLabel,
      config: [WarningLevelUREnum.FAULT, WarningLevelUREnum.VIOLATION, WarningLevelUREnum.WARNING],
    };
  }
}
