import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { LogsKawasakiFiltersService } from '../../../modules/logs/modules/logs-kawasaki/components/logs-kawasaki-filters/logs-kawasaki-filters.service';
import { LogsKawasakiCategoryInterface } from '../../../modules/logs/modules/logs-kawasaki/interfaces/logs-kawasaki-category.interface';
import { LogsKawasakiFiltersStore } from '../../../modules/logs/modules/logs-kawasaki/store/logs-kawasaki-filters.store';
import { DictionaryInterface } from '../../interfaces/dictionary.interface';
import { LogCategoryIconInterface } from '../../interfaces/log-category-icon.interface';
import { UserConfigInterface } from '../../interfaces/user-config.interface';
import { FormCheckboxComponent } from '../../modules/form/components/form-checkbox/form-checkbox.component';

@Component({
  selector: 'app-logs-filter-category',
  templateUrl: './logs-filter-category.component.html',
  styleUrls: ['./logs-filter-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NgScrollbarModule,
    FormCheckboxComponent,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
  ],
})
export class LogsFilterCategoryComponent implements OnInit {
  @Input() categories: LogsKawasakiCategoryInterface[];
  @Input() iconParser: (categoryNumber: number) => LogCategoryIconInterface;

  categoriesFormGroup: FormGroup<any> = new FormGroup({});
  shouldDisplay = false;
  private readonly saveDelay = 3000;

  constructor(
    private logsKawasakiFiltersService: LogsKawasakiFiltersService,
    private changeDetectorRef: ChangeDetectorRef,
    private state: LogsKawasakiFiltersStore,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.logsKawasakiFiltersService.getConfig$().pipe(
      tap((config: UserConfigInterface<number[]>) => {
        this.createForm(config);
      }),
    ).subscribe(() => {
      this.shouldDisplay = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  private createForm(config: UserConfigInterface<number[]>): void {
    this.categories.forEach((category: LogsKawasakiCategoryInterface) => {
      this.categoriesFormGroup.addControl(
        category.code.toString(),
        this.formBuilder.control((config.config as number[]).includes(category.code)),
        {
          emitEvent: false,
        },
      );
    });

    // Watch on categories' changes in order to send new data to store.
    this.categoriesFormGroup.valueChanges.pipe(
      tap((categoriesFormGroupValue: DictionaryInterface<boolean>) => {
        if (!this.logsKawasakiFiltersService.isAnyCategoryChecked(categoriesFormGroupValue)) {
          this.state.resetCategories();
        } else {
          this.state.updateCategories(this.getCategoriesState(categoriesFormGroupValue));
        }
        this.changeDetectorRef.detectChanges();
      }),
    ).subscribe();

    this.initConfigSave();
  }

  private initConfigSave(): void {
    this.categoriesFormGroup.valueChanges.pipe(
      debounceTime(this.saveDelay),
      switchMap((categories: DictionaryInterface<boolean>) => this.logsKawasakiFiltersService.saveConfig$(categories)),
    ).subscribe();
  }

  private getCategoriesState(categoriesFormGroupValue: DictionaryInterface<boolean>): number[] {
    return this.categories.reduce((payload: number[], cat: LogsKawasakiCategoryInterface) => {
      const isCategoryChecked: boolean = categoriesFormGroupValue[cat.code];

      if (isCategoryChecked) {
        payload.push(cat.code);
      }

      return payload;
    }, []);
  }
}
