<div *ngIf="data.length > 0" class="table-basic">
  <div class="table-basic__head">
    <div
      *ngFor="let header of Object.keys(data[0]); let idx = index"
      class="table-basic__cell table-basic__cell--header"
      [style.width]="(config && config.columnSizes.length > 0) ? config.columnSizes[idx] + '%' : 'auto'"
    >
      {{ header }}
    </div>
  </div>
  <div *ngFor="let row of data" class="table-basic__row">
    <div
      *ngFor="let cell of Object.values(row); let idx = index"
      class="table-basic__cell"
      [style.width]="(config && config.columnSizes.length > 0) ? config.columnSizes[idx] + '%' : 'auto'"
    >
      {{ cell }}
    </div>
  </div>
</div>
