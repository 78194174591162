import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigTypeEnum } from '../../../../enums/user-config-type.enum';
import { UserConfigInterface } from '../../../../interfaces/user-config.interface';
import { apiUrlUserConfig, apiUrlUserConfigs } from '../../constants/api-url.constant';
import { ApiResourceUserConfigServiceInterface } from './api-resource-user-config-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceUserConfigService implements ApiResourceUserConfigServiceInterface {
  constructor(
    private httpClientService: HttpClient,
  ) {}

  getConfigs$(configType: UserConfigTypeEnum): Observable<Omit<UserConfigInterface, 'config'>[]> {
    let params = new HttpParams();
    params = params.set('config_type', configType);

    return this.httpClientService.get<Omit<UserConfigInterface, 'config'>[]>(apiUrlUserConfigs, {
      params,
    });
  }

  createConfig$<T>(
    config: Omit<UserConfigInterface, 'id'>,
  ): Observable<UserConfigInterface<T>> {
    return this.httpClientService.post<UserConfigInterface<T>>(apiUrlUserConfigs, config);
  }

  getConfig$<T>(configId: string): Observable<UserConfigInterface<T>> {
    return this.httpClientService.get<UserConfigInterface<T>>(apiUrlUserConfig(configId));
  }

  updateConfig$<T>(configId: string, config: Omit<UserConfigInterface<T>, 'id'>): Observable<UserConfigInterface<T>> {
    return this.httpClientService.put<UserConfigInterface<T>>(apiUrlUserConfig(configId), config);
  }
}
