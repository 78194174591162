import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import dayjs from 'dayjs/esm';
import { ColorService } from 'src/app/shared/services/color.service';
import { ChartCalendarHeatmapItemInterface } from './chart-calendar-heatmap-item.interface';

@Component({
  selector: 'app-chart-calendar-heatmap',
  templateUrl: './chart-calendar-heatmap.component.html',
  styleUrls: ['./chart-calendar-heatmap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCalendar],
})
export class ChartCalendarHeatmapComponent implements OnChanges, AfterViewInit {
  @Input() data: ChartCalendarHeatmapItemInterface[];
  @Input() min: number;
  @Input() max: number;
  @Input() color: string;
  @ViewChild('wrapper') wrapperRef: ElementRef<HTMLElement>;
  @ViewChild('tooltip') tooltipRef: ElementRef<HTMLElement>;

  startAt: Date = dayjs().toDate();

  private readonly steps = 10;

  constructor(
    private colorService: ColorService,
  ) {}

  ngAfterViewInit(): void {
    this.updateStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.min || changes.max || changes.color) {
      this.updateStyle();
    }
  }

  getDateClass(): (date: Date) => MatCalendarCellCssClasses | '' {
    return (date: Date): MatCalendarCellCssClasses | '' => {
      const styler = this.data.find(item => {
        return item.date.valueOf() === date.valueOf();
      });

      return styler ? this.selector(Math.round(this.steps * (styler.value - this.min) / (this.max - this.min))) : '';
    };
  }

  private updateStyle(): void {
    if (
      !this.wrapperRef ||
      !this.wrapperRef.nativeElement
    ) {
      return;
    }

    for (let idx = 0, len = this.data.length; idx < len; ++idx) {
      const selectorItems: NodeListOf<Element> =
        this.wrapperRef.nativeElement.querySelectorAll(`.${this.selector(idx)}`);
      const color: string = this.colorService.hexToRgbA(this.color, idx / this.steps);

      selectorItems.forEach(selectorItem => {
        (selectorItem as HTMLElement).style.backgroundColor = color;
      });

      const dayBoxes = this.wrapperRef.nativeElement.querySelectorAll<HTMLElement>(`.mat-calendar-body-cell`);
      dayBoxes.forEach((dayBox: HTMLElement, index: number) => {
        dayBox.addEventListener('mouseenter', () => {
          const boxDay = dayjs(this.data[0].date)
            .add(index, 'days')
            .toDate();
          const boxData = this.data.find((dataItem: ChartCalendarHeatmapItemInterface) => dataItem.date.valueOf() === boxDay.valueOf());

          if (boxData) {
            const boxValue = boxData.value;

            this.tooltipRef.nativeElement.innerHTML = `${new Date(boxDay).toLocaleString()}<br/>Count: <strong>${boxValue}</strong>`;
            this.tooltipRef.nativeElement.style.left = `${dayBox.offsetLeft + dayBox.offsetWidth / 2 - 1}px`;
            this.tooltipRef.nativeElement.style.top = `${dayBox.offsetTop - 8}px`;
            this.tooltipRef.nativeElement.style.display = 'block';
          }
        }, false);

        dayBox.addEventListener('mouseleave', () => {
          this.tooltipRef.nativeElement.style.display = 'none';
        }, false);
      });
    }
  }

  private selector(normalisedValue: number) {
    return `date-range-${normalisedValue}`;
  }
}
