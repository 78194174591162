import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateRangeInterface } from '../../../../../shared/interfaces/date-range.interface';
import { LogsKawasakiFiltersStateInterface } from '../interfaces/logs-kawasaki-filters-state.interface';

@Injectable()
export class LogsKawasakiFiltersStore {
  state$: BehaviorSubject<LogsKawasakiFiltersStateInterface> = new BehaviorSubject<LogsKawasakiFiltersStateInterface>({
    device: '',
    start: null,
    end: null,
    categories: null,
    search: null,
    limit: null,
    page: 1,
    error_code: null,
  });

  getState$(): Observable<LogsKawasakiFiltersStateInterface> {
    return this.state$.asObservable();
  }

  updateDevice(deviceId: string): void {
    this.state$.next({
      ...this.state$.value,
      device: deviceId,
    });
  }

  updateDateRange(dateRange: DateRangeInterface): void {
    this.state$.next({
      ...this.state$.value,
      start: dateRange.dateFrom.toISOString(),
      end: dateRange.dateTo.toISOString(),
    });
  }

  updateLimit(limit: number): void {
    this.state$.next({
      ...this.state$.value,
      limit,
    });
  }

  updateCategories(categories: number[]): void {
    this.state$.next({
      ...this.state$.value,
      categories,
    });
  }

  resetCategories(): void {
    this.state$.next({
      ...this.state$.value,
      categories: [],
    });
  }

  updateSearch(search: string): void {
    this.state$.next({
      ...this.state$.value,
      search,
    });
  }

  updateErrorGroup(errorCode: string): void {
    this.state$.next({
      ...this.state$.value,
      error_code: errorCode,
    });
  }
}
